// src/Sidebar.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import slugify from 'slugify';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const Sidebar = () => {
	const [djs, setDjs] = useState([]);

	const programs = [
		{ code: 'rs', name: 'Main Program' },
		{ code: 'ia', name: 'Ivy Arum Session' },
		{ code: 'hr', name: 'Heimliday Radio' },
		{ code: 'bo', name: 'byyb.out' },
	];

	const socialLinks = [
		{ name: '💧 Instagram', url: 'https://instagram.com/byyb.radio' },
		{ name: '📺 YouTube', url: 'https://youtube.com/@byyb_radio' },
		{ name: '🔊 SoundCloud', url: 'https://soundcloud.com/byyb_radio' },
	];

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		axios
			.get('/api/djs')
			.then((response) => {
				const sortedDjs = response.data.sort((a, b) => a.name.localeCompare(b.name));
				setDjs(sortedDjs);
			})
			.catch((error) => console.error('Error fetching DJs:', error));
	}, []);

	const handleProgramSelect = (programCode) => {
		const queryParams = new URLSearchParams();

		if (programCode) {
			queryParams.set('program', programCode);
		}

		navigate({
			pathname: '/',
			search: queryParams.toString(),
		});

		window.scrollTo(0, 0);
	};

	const handleDjSelect = (djName) => {
		const queryParams = new URLSearchParams();

		if (djName) {
			const djSlug = slugify(djName, { lower: true, strict: true });
			queryParams.set('dj', djSlug);
		}

		navigate({
			pathname: '/',
			search: queryParams.toString(),
		});

		window.scrollTo(0, 0);
	};

	const resetFilters = () => {
		navigate({
			pathname: '/',
			search: '',
		});
		window.scrollTo(0, 0);
	};

	return (
		<div className="sidebar">
			{/* Programs Section */}
			<ul>
				<li onClick={resetFilters} className="sidebar-heading">
					All Programs
				</li>
				{programs.map((program) => (
					<li
						key={program.code}
						onClick={() => handleProgramSelect(program.code)}
						className="sidebar-item"
					>
						{program.name}
					</li>
				))}
			</ul>

			{/* DJs/Artists Section */}
			<ul>
				<li onClick={resetFilters} className="sidebar-heading">
					All DJ/Artists
				</li>
				{djs.map((dj) => (
					<li key={dj.id} onClick={() => handleDjSelect(dj.name)} className="sidebar-item">
						{dj.name}
					</li>
				))}
			</ul>

			{/* Social Links Section */}
			<ul>
				<li className="sidebar-heading">Our Social Media</li>
				{socialLinks.map((social) => (
					<li key={social.name} className="sidebar-item">
						<a href={social.url} target="_blank" rel="noopener noreferrer">
							{social.name}
						</a>
					</li>
				))}
			</ul>

			{/* Contact and Additional Information */}
			<ul>
				<li>💌 byyb.radio@gmail.com</li>
			</ul>
			<ul>
				<li>🪴 沪ICP备2024084931号-1</li>
			</ul>
			<ul>
				<li>🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴</li>
				<li>🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴</li>
				<li>🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴🪴</li>
			</ul>
		</div>
	);
};

export default React.memo(Sidebar);
