// src/DjSets.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import LineupItem from './LineupItem';
import { useLocation, useNavigate } from 'react-router-dom';
import slugify from 'slugify';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const DjSets = ({ onSetSelect }) => {
	const [djSets, setDjSets] = useState([]);
	const [djs, setDjs] = useState([]);
	const [djInfo, setDjInfo] = useState(null);
	const [programInfo, setProgramInfo] = useState(null);
	const [error, setError] = useState(null);

	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	const location = useLocation();
	const navigate = useNavigate();

	const [djsLoaded, setDjsLoaded] = useState(false);

	// Get query parameters from URL
	const queryParams = new URLSearchParams(location.search);
	const selectedDjSlug = queryParams.get('dj');
	const selectedProgram = queryParams.get('program');
	const pageParam = queryParams.get('page');
	const page = pageParam ? parseInt(pageParam, 10) : 1;

	// Fetch DJs when component mounts
	useEffect(() => {
		const fetchDjs = async () => {
			try {
				const response = await axios.get('/api/djs');
				setDjs(response.data);
				setDjsLoaded(true);
			} catch (error) {
				console.error('Error fetching DJs:', error);
				setError('Failed to load DJs.');
				setDjsLoaded(true); // Even on error, prevent blocking
			}
		};

		fetchDjs();
	}, []);

	useEffect(() => {
		if (!djsLoaded) return; // Wait until DJs are loaded

		// Update currentPage state
		setCurrentPage(page);

		// Fetch DJ sets
		const loadDjSets = async () => {
			setError(null);

			let url = `/api/performances/?page=${page}&limit=71`;
			let params = [];

			let selectedDjId = null;

			if (selectedDjSlug && djs.length > 0) {
				// Find the DJ by slug
				const dj = djs.find(
					(dj) => slugify(dj.name, { lower: true, strict: true }) === selectedDjSlug
				);
				if (dj) {
					selectedDjId = dj.id;
				} else {
					setError('DJ not found');
					return;
				}
			}

			if (selectedDjId) {
				params.push(`dj_id=${selectedDjId}`);
			}

			if (selectedProgram) {
				params.push(`program=${selectedProgram}`);
			}

			if (params.length > 0) {
				url += '&' + params.join('&');
			}

			try {
				const response = await axios.get(url);
				const { sets, djInfo, programInfo, total_pages, current_page } = response.data;

				setDjSets(sets);
				setDjInfo(djInfo);
				setProgramInfo(programInfo);
				setTotalPages(total_pages);
				setCurrentPage(current_page);

				// Scroll to top after data is loaded
				window.scrollTo(0, 0);
			} catch (error) {
				console.error('Error fetching DJ sets:', error);
				setError('Failed to load DJ sets. Please try again later.');
			}
		};

		loadDjSets();
	}, [selectedDjSlug, selectedProgram, page, djs, djsLoaded]);

	const handlePageChange = (newPage) => {
		queryParams.set('page', newPage);
		navigate({ search: queryParams.toString() });
	};

	const renderPagination = () => {
		const pages = [];
		const maxPageButtons = 5;
		let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
		let endPage = startPage + maxPageButtons - 1;

		if (endPage > totalPages) {
			endPage = totalPages;
			startPage = Math.max(1, endPage - maxPageButtons + 1);
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(
				<button
					key={i}
					onClick={() => handlePageChange(i)}
					disabled={i === currentPage}
					className={i === currentPage ? 'active' : ''}
				>
					{i}
				</button>
			);
		}

		return (
			<div className="pagination">
				{currentPage > 1 && (
					<button onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
				)}
				{pages}
				{currentPage < totalPages && (
					<button onClick={() => handlePageChange(currentPage + 1)}>Next</button>
				)}
			</div>
		);
	};

	// Update the URL when a DJ set is selected
	const handleSetSelect = (set) => {
		const queryParams = new URLSearchParams(location.search);

		const performanceFullName = set.performance_full_name;
		const performanceSlug = slugify(performanceFullName, { lower: true, strict: true });

		// Clear previous filtering conditions
		queryParams.delete('dj');
		queryParams.delete('program');
		queryParams.delete('set');

		queryParams.set('set', performanceSlug);

		// Do not delete 'page' parameter here
		// The 'page' parameter will be preserved in the URL

		navigate({ search: queryParams.toString() });

		onSetSelect(set);

		// Scroll to top after selecting a set
		window.scrollTo(0, 0);
	};

	// Individual DJ set item
	const SetItem = React.memo(({ set, onSetSelect }) => {
		const videoRef = useRef(null);
		const [listenCount, setListenCount] = useState(set.listen_count);

		const handleMouseEnter = () => {
			if (videoRef.current) {
				videoRef.current.play().catch((error) => {
					console.error('Error playing video snippet:', error);
				});
				videoRef.current.style.opacity = 1;
			}
		};

		const handleMouseLeave = () => {
			if (videoRef.current) {
				videoRef.current.pause();
				videoRef.current.currentTime = 0;
				videoRef.current.style.opacity = 0;
			}
		};

		// Handle click to select DJ set and increment listen count
		const handleClick = () => {
			onSetSelect(set);

			// Increment listen count
			axios
				.post(`/api/performances/${set.id}/increment_listen_count/`)
				.then((response) => {
					setListenCount(response.data.listen_count);
					set.listen_count = response.data.listen_count;
				})
				.catch((error) => {
					console.error('Error incrementing listen count:', error);
				});
		};

		return (
			<div
				className="set-item"
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<img
					src={set.square_thumbnail_url}
					alt={set.identifier}
					width="200"
					height="200"
					className="set-thumbnail"
					loading="lazy"
				/>
				{set.snippet_file_url && (
					<video
						ref={videoRef}
						src={set.snippet_file_url}
						width="200"
						height="200"
						className="set-snippet"
						muted
						preload="metadata"
						style={{ opacity: 0, transition: 'opacity 0.3s' }}
					/>
				)}
				{/* <p>🎧 {listenCount}</p> */}
			</div>
		);
	});

	return (
		<div className="dj-sets-container">
			{djInfo && selectedDjSlug && (
				<div className="dj-info">
					<h1 className="dj-name">{djInfo.name}</h1>
					<p className="dj-bio">{djInfo.bio}</p>
				</div>
			)}

			{programInfo && selectedProgram && (
				<div className="dj-info">
					<h1 className="dj-name">{programInfo.name}</h1>
					<p className="dj-bio">{programInfo.intro}</p>
				</div>
			)}

			{djSets.length > 0 && (
				<div className="dj-sets">
					{!selectedDjSlug && !selectedProgram && <LineupItem />}
					{djSets.map((set) => (
						<SetItem key={set.id} set={set} onSetSelect={handleSetSelect} />
					))}
				</div>
			)}

			{error && <p className="error">{error}</p>}

			{totalPages > 1 && renderPagination()}
		</div>
	);
};

export default React.memo(DjSets);
