// src/App.js

import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from './Sidebar';
import DjSets from './DjSets';
import MusicPlayer from './MusicPlayer';
import TopMenu from './TopMenu';
import Apply from './Apply';
import './App.css';
import { Helmet } from 'react-helmet';
import { Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';
import slugify from 'slugify';

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [currentTrack, setCurrentTrack] = useState('');
  const [currentSet, setCurrentSet] = useState(null);
  const [djInfo, setDjInfo] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Function to handle DJ set selection
  const handleSetSelection = useCallback((set) => {
    setCurrentTrack(set.audio_file_url);
    setCurrentSet(set);
    setDjInfo(set.dj);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const setSlug = queryParams.get('set');

    if (setSlug) {
      // Fetch the DJ set by slug
      axios
        .get(`/api/performances/slug/${setSlug}/`)
        .then((response) => {
          const set = response.data;
          handleSetSelection(set);
        })
        .catch((error) => {
          console.error('Error fetching DJ set:', error);
        });
    } else {
      // If no set is selected, reset the music player
      setCurrentTrack('');
      setCurrentSet(null);
      setDjInfo(null);
    }
  }, [location.search, handleSetSelection]);

  return (
    <div className="app">
      <Helmet>
        {/* Your meta tags here */}
      </Helmet>

      <TopMenu toggleSidebar={toggleSidebar} onSetSelect={handleSetSelection} />

      <div className={`sidebar-container ${sidebarOpen ? 'show' : ''}`}>
        <Sidebar />
      </div>

      <div className="main-content">
        <Routes>
          <Route path="/" element={<DjSets onSetSelect={handleSetSelection} />} />
          <Route path="/apply" element={<Apply />} />
          {/* Add other routes here */}
        </Routes>
      </div>
      <MusicPlayer audioSrc={currentTrack} selectedSet={currentSet} djInfo={djInfo} />
    </div>
  );
};

export default App;
